import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser";
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitle from "../components/UI/PageTitle"
import { Tab } from '@headlessui/react'
import { Link as AnchorLinkScroll } from "react-scroll";
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

const MenuPage = () => {
  const data = useStaticQuery(
    graphql`
      query MenuQuery {
        contentJson(fileID: {eq: "menu"}) {
          fileID
          menuIntro
          menuSections {
            sectionName
            sectionText
            sectionPrice
            sectionCat {
              catTitle
              catText
              catPrice
              catItems {
                name
                desc
                price
              }
            }
          }
        }
      }
    `
  )
  useFirestoreConnect([
     { collection: 'menu', doc: 'origin-cafe' }
  ])
  const content = useSelector(({ firestore: { data } }) => data.menu && data.menu['origin-cafe'])
  //const content = data.contentJson;
  if (typeof content === 'undefined') {
    return null
  }
  return (
    <Layout>
      <Seo title="Menu" />
      <div>
        <PageTitle title="Menu" intro={content.menuIntro} />

        <Tab.Group>
          <div className="container mx-auto ">
            <Tab.List className="overflow-x-auto">
              <div className="border-b border-gray-200 flex flex-nowrap whitespace-nowrap ">
              {content.menuSections.map((section, i) => (
                <Tab as={Fragment} key={i}>
                  {({ selected }) => (
                    <button className={"btn shadow-none border-0 border-b-4 rounded-none -m-px whitespace-nowrap" + (selected ? " text-primary border-primary" : " text-black border-transparent")}>{section.sectionName}</button>
                  )}
                </Tab>
              ))}
              </div>
            </Tab.List>
          </div>
          <Tab.Panels>
            {content.menuSections.map((section, i) => (
              <Tab.Panel key={i} className="min-h-screen">
                {(section.sectionText || section.sectionPrice) && (
                  <div className="pt-6 container mx-auto ">
                  {section.sectionText && (
                    <div className="text-sm">{section.sectionText}</div> 
                  )}
                  {section.sectionPrice && (
                    <div className="font-bold pt-2">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XPF' }).format(section.sectionPrice)}</div> 
                  )}
                  </div>
                )}
                <div className="pt-4 container mx-auto content-panel">
                  {section.sectionCat.map((item, i) => (
                    <div key={i} id={"#cat-" + i} className="py-6">
                      <h2 className="text-center font-serif font-normal">{item.catTitle}</h2>
                      <div className="pt-2">
                        {item.catText && (
                          <div className="text-sm text-center pt-4 text-primary">{parse(item.catText)}</div>
                        )}
                        {item.catPrice && (
                          <div className="text-center font-bold pt-2 text-primary">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XPF' }).format(item.catPrice)}</div> 
                        )}  
                        <div className="divide-y divide-primary-light divide-dashed max-w-xl mx-auto pt-2">
                          {item.catItems.map((catItem, index) => (
                              <>
                              <div className="sm:flex sm:justify-between py-4" key={"catItem-" + index}>
                                <div>
                                  <div className="font-bold">{catItem.name}</div>
                                  {catItem.desc && (
                                    <div className="text-sm text-primary">{parse(catItem.desc)}</div>
                                  )}
                                </div>
                                {catItem.price && (
                                  <div>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XPF' }).format(catItem.price)}</div>
                                )}
                              </div>
                                {catItem.composition && (
                                  <div className="grid grid-cols-1 gap-4 pt-10 md:grid-cols-2">
                                  {catItem.composition.map((compo, index) => (
                                      <div className="bg-gray-100 p-5">
                                        <div className="flex">
                                          <div className="text-[25px] text-[#ed6b77]">{index+1}</div>
                                          &nbsp;&nbsp;
                                          <div className="flex pt-[7px] mb-[7px] flex-1 items-center border-b border-[#ed6b77] border-dashed	">
                                            <div className="font-bold">{compo.name}</div>
                                            &nbsp;
                                            <div className="font-bold flex-grow text-[#EE7E88]">
                                              {(compo.desc === 'undefined') && (
                                                  <>
                                                  &nbsp;
                                                  </>
                                              )}
                                              {(compo.desc !== 'undefined') && (
                                                  <>
                                                    {compo.desc}
                                                  </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="grid grid-rows-4 gap-0 md:grid-flow-col md:auto-cols-auto">
                                          {compo.contenu.map(content => <div>{content}</div>)}
                                        </div>
                                      </div>
                                  ))}
                                </div>
                                )}
                              </>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))} 
                  <div className="text-center text-primary text-sm italic pb-8">Prix indiqués en Francs CFP (TTC).</div>
                </div>         
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  )
}

export default MenuPage